<template>
  <DefaultModal
    :title="$t('manage_filters')"
    ref="mng_filters_modal"
    @submit="$emit('submit-filter', $event)"
    :loading="loading"
  >
    <template v-slot:content>
      <v-select
        v-model="selectedItem"
        :items="filteredItems"
        :label="$t('select_filter')"
        item-text="filter_key"
        item-value="filter_id"
        name="filter"
        outlined
      ></v-select>
    </template>
  </DefaultModal>
</template>

<script>
import { mapActions } from 'vuex'

import DefaultModal from '@/components/DefaultModal'

import FilterActions from '@/store/operation/filter/actions-types'

export default {
  name: 'FilterAssocModal',

  components: {
    DefaultModal,
  },

  data: () => ({
    loading: false,
    items: [],
    selectedItem: null,
    tenantIdFilter: null
  }),

  mounted() {
    this.fetchData()
  },

  computed: {
    filteredItems() {
      if (this.tenantIdFilter) {
        return this.items.filter(f => f.tenant_id == this.tenantIdFilter || f.filter_id === null)
      }

      return this.items
    },
  },

  methods: {
    ...mapActions('operation/filter', [
      FilterActions.GET_ALL_FILTERS,
    ]),

    fetchData() {
      this.loading = true

      this[FilterActions.GET_ALL_FILTERS]()
        .then((result) => {
          this.items = [
            {
              filter_key: "",
              filter_id: null,
            },
            ...result.items
          ]

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    open(filterId = null) {
      this.selectedItem = filterId
      this.$refs.mng_filters_modal.openModal()
    },

    close() {
      this.selectedItem = null
      this.$refs.mng_filters_modal.closeModal()
    },
  },
}
</script>
