<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        outlined
        :color="hover ? 'grey lighten-3' : 'white'"
      >
        <v-img
          class="white--text align-center custom-thumb"
          height="200px"
          align="center"
          justify="center"
          @dblclick="$emit('click-thumb', data)"
        >
          <v-icon color="defaultIconColor" class="thumb-icon">mdi-database</v-icon>
        </v-img>

        <v-card-actions class="card-actions">

          <v-icon color="defaultIconColor">mdi-database-outline</v-icon>

          <v-tooltip open-delay="1000" bottom>
            <template v-slot:activator="{ on, attrs }">

              <v-card-subtitle
                v-bind="attrs"
                v-on="on"
                class="custom-subtitle prevent-select"
              >{{ data.name }}</v-card-subtitle>

            </template>
            <span>{{ data.name }}</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <ContentActionsPopup
              :data="data"
              :filter-type="repositoryNodeType()"
            />
          </v-menu>

        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<style scoped>
  .custom-thumb {
    cursor: pointer;
    background-color: #DADCE0;
  }

  .thumb-icon {
    font-size: 150px;
  }

  .card-actions {
    padding: 1px 8px 1px 8px;
  }

  .custom-subtitle {
    font-weight: 500;
    color: rgb(60, 64, 67) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px 12px !important;
  }

  .v-card {
    border-color: #DADCE0 !important;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
</style>

<script>

import NodeTypes from '@/store/content/actions/node-types.js'

export default {
  name: 'RepositoryCard',

  beforeCreate: function () {
    this.$options.components.ContentActionsPopup = require('@/components/content/ContentActionsPopup.vue').default
  },

  props: {
    data: {
      type: Object
    }
  },

  methods: {
    repositoryNodeType() {
      return NodeTypes.NODE_REPOSITORY
    },
  },
}

</script>
